import type { Screener, ScreenerLocation } from "@/types/screeners";

export const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase(),
    )
    .replace(/\s+/g, "");
};

export const screenerHasCompletedSessions = (screener?: Screener) =>
  (screener?.screenings ?? 0) > 0;

export const locationHasCompletedSessions = (location?: ScreenerLocation) =>
  (location?.sessionCount ?? 0) > 0;

export const truncateString = (str: string, maxLength: number) => {
  if (str.length <= maxLength) return str;
  return str.slice(0, maxLength) + "...";
};

export const downloadBlob = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
};
