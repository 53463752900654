import { Checkbox, Image, Tooltip } from "@mantine/core";
import type {
  CellContext,
  Column,
  ColumnDef,
  Row,
  Table,
} from "@tanstack/react-table";
import get from "lodash/get";
import Link from "next/link";
import { type CSSProperties } from "react";

import ActionMenu from "@/components/table/actionMenu";
import {
  BadgeCell,
  CompetencyCell,
  LocationNameCell,
  ShareLinkCell,
  ReprocessCell,
  MultiBadgesCell,
  UnviewedIndicatorText,
} from "@/components/table/cells";
import { useTrackAnalytics } from "@/hooks/analytics.hooks";
import { type DefaultFilter } from "@/hooks/use-filter-state.hook";
import { type ApiColumn } from "@/types/api";
import type {
  Competency,
  Screener,
  ScreenerLocation,
  Screening,
} from "@/types/screeners";
import { type User } from "@/types/user";
import { type Nullable } from "@/types/utils";
import {
  locationHasCompletedSessions,
  screenerHasCompletedSessions,
  truncateString,
} from "@/utils/helpers";
import { borderColorCodes, rowColorCodes } from "@/utils/ui";

const customFilterFn = <TableData,>(
  row: Row<TableData>,
  columnId: string,
  { defaultFilter: filterValue }: DefaultFilter<string[]>,
) => {
  if (filterValue.length === 0) {
    return true;
  }

  const cellValue: Nullable<string> = row.getValue(columnId);

  // For competency columns, only return true if the value includes "High"
  if (columnId.startsWith("competencies")) {
    const competency = cellValue as unknown as Competency;
    return typeof competency === "object" && competency?.score === "High";
  }
  if (columnId.startsWith("isVisibleOnPdf")) {
    return cellValue !== null && filterValue.includes(cellValue);
  }

  return cellValue !== null && filterValue.includes(cellValue.toLowerCase());
};

const highlightFilterFn = (
  row: Row<Screening>,
  columnId: string,
  { defaultFilter: filterValue }: DefaultFilter<string[]>,
) => {
  if (!row.original.highlightColor) {
    return filterValue.includes("none");
  }
  return filterValue.includes(row.original.highlightColor);
};

const highMediumLowSortingFn = (
  rowA: Row<Screening>,
  rowB: Row<Screening>,
  columnId: string,
) => {
  // for sorting specific columns
  const priorityMap = {
    High: 3,
    Medium: 2,
    Low: 1,
  };

  const valueA = rowA.getValue(columnId);
  const valueB = rowB.getValue(columnId);

  const priorityA = priorityMap[valueA as keyof typeof priorityMap] || 0;
  const priorityB = priorityMap[valueB as keyof typeof priorityMap] || 0;
  return priorityA - priorityB;
};

const timeAndWordsSortingFn =
  (fieldName: "totalTimeInSeconds" | "totalWords") =>
  (rowA: Row<Screening>, rowB: Row<Screening>) =>
    rowA.original[fieldName] - rowB.original[fieldName];

const timeAndWordsFilterFn =
  (fieldName: "highTimeSpent" | "highWordCount") =>
  (
    row: Row<Screening>,
    field: string,
    { defaultFilter: filterValue }: DefaultFilter<string[]>,
  ) => {
    // The CheckBoxFilter component naturally outputs its filters as an array.
    // In the case of the time/words filter function there is only one possible
    // value, meaning that if there is anything in the filter array we know to
    // filter on high percentile time spent/word count
    if (filterValue?.length > 0) {
      return !!row.original[fieldName];
    }

    return true;
  };

const companyFitFilterFn = (
  row: Row<Screening>,
  columnId: string,
  { defaultFilter: filterValue }: DefaultFilter<string[]>,
): boolean => {
  const ko = row.original.qualifyingPassRate;
  const overallFit = row.original.overallFit;

  // Return true to keep the row if it matches the filter criteria
  let fitsCriteria = true;
  if (filterValue.includes("high: overall fit") && overallFit !== "High") {
    fitsCriteria = false;
  }
  if (filterValue.includes("100% knockout") && ko !== 100) {
    fitsCriteria = false;
  }
  return fitsCriteria;
};

const getCommonPinningStyles = <T,>(
  column: Column<T>,
  row?: Row<Screening>,
): CSSProperties => {
  const isPinned = column.getIsPinned();
  // We have to use box shadow rather than border to maintain it with sticky positioning
  const boxShadow = (() => {
    if (!isPinned) {
      return;
    }

    if (column.getIsFirstColumn("left") && row?.original.highlightColor) {
      return `inset 4px 0px 0px 0px ${borderColorCodes[row?.original.highlightColor]}`;
    }

    if (column.getIsLastColumn("left")) {
      return `inset -1px 0px 0px 0px #F2F2F2`;
    }
  })();

  return {
    boxShadow,
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    position: isPinned ? "sticky" : "relative",
    zIndex: isPinned ? 1 : 0,
  };
};

export const getJobPinningStyles = (
  column: Column<Screening>,
  row?: Row<Screening>,
): CSSProperties => ({
  ...getCommonPinningStyles(column, row),
  backgroundColor: row?.original.highlightColor
    ? rowColorCodes[row.original.highlightColor]
    : "white",
});

export const getLocationPinningStyles = (
  column: Column<ScreenerLocation>,
  row?: Row<ScreenerLocation>,
): CSSProperties => ({
  ...getCommonPinningStyles(column),
  backgroundColor:
    !row || locationHasCompletedSessions(row.original) ? "white" : "#F6F6F6",
});

export const getScreenerPinningStyles = (
  column: Column<Screener>,
  row?: Row<Screener>,
): CSSProperties => ({
  ...getCommonPinningStyles(column),
  backgroundColor:
    !row || screenerHasCompletedSessions(row.original) ? "white" : "#F6F6F6",
});

export const columnSizingHandler = <T,>(
  tableHead: HTMLTableCellElement | null,
  table: Table<T>,
  column: Column<T>,
) => {
  // Only update columnSizing if the element's dimensions have actually changed to prevent an infinite loop.
  if (
    tableHead &&
    table.getState().columnSizing[column.id] !==
      tableHead.getBoundingClientRect().width
  ) {
    table.setColumnSizing((prevSizes) => ({
      ...prevSizes,
      [column.id]: tableHead.getBoundingClientRect().width,
    }));
  }
};

const timeAndWordsCell = (
  column: ApiColumn,
  defaults: { accessorKey: string; header: string },
  isWords = true,
): ColumnDef<Screening> => ({
  ...defaults,
  filterFn: timeAndWordsFilterFn(isWords ? "highWordCount" : "highTimeSpent"),
  cell: textCell,
  sortingFn: timeAndWordsSortingFn(
    isWords ? "totalWords" : "totalTimeInSeconds",
  ),
});

const createdAtCell = <
  TableData extends Screening | ScreenerLocation | Screener,
>() => ({
  filterFn: (
    row: Row<TableData>,
    columnId: string,
    {
      after = -Infinity,
      before = Infinity,
      sessionStatus,
    }: { after?: number; before?: number; sessionStatus?: string[] },
  ) => {
    if (Object.hasOwn(row.original, "sessionStatus")) {
      const screening = row.original as Screening;

      // The CheckBoxFilter component naturally outputs its filters as an array.
      // Because the sessionStatus has only one possible value ("completed"),
      // we know that if there is a value in the array we only need to check
      // the row's session status.
      if ((sessionStatus?.length ?? 0) > 0) {
        return screening.sessionStatus === "completed";
      }
    }

    const cellValue = new Date(row.getValue(columnId)).valueOf();
    return cellValue >= after && cellValue <= before;
  },
  cell: (info: CellContext<TableData, string>) => {
    const isPartialSessionStatus =
      // Even though we don't know this is a screening, casting is safe here as we want this to return false for
      // screener locations regardless.
      (info.row.original as Screening)?.sessionStatus === "partial";
    const cellValue = info.getValue();

    return (
      <div
        className={`w-full flex gap-2 justify-start items-center ${
          isPartialSessionStatus ? "text-red-600" : ""
        }`}
      >
        {cellValue
          ? new Date(cellValue).toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
              year: "numeric",
            })
          : "—"}
        {isPartialSessionStatus && (
          <Image
            alt="This session is incomplete/partial"
            src="/assets/red-x.svg"
          />
        )}
      </div>
    );
  },
});

const textCell = <TData,>(info: CellContext<TData, string>) =>
  info.getValue()?.length > 50 ? (
    <Tooltip
      label={info.getValue()}
      withArrow
      arrowSize={8}
      c="black"
      color="white"
      w={400}
      className="border border-gray-200 z-[1000]"
    >
      <span className="truncate">{truncateString(info.getValue(), 50)}</span>
    </Tooltip>
  ) : (
    info.getValue() || "—"
  );

const badgeFilledCell = <TData,>(column: ApiColumn, suffix?: string) => {
  return (info: CellContext<TData, string>) => {
    const value = info.getValue();

    return (
      <BadgeCell fieldName={column.fieldName} filled value={value}>
        {`${value}${suffix || ""}`}
      </BadgeCell>
    );
  };
};

const defaultCell = <TData, TValue>(column: ApiColumn) => ({
  header: column.displayName,
  accessorKey: column.fieldName,
  filterFn: customFilterFn,
  cell: (info: CellContext<TData, TValue>) => info.getValue() || "—",
});

export const formatScreenerColumns = (
  columns: ApiColumn[],
): ColumnDef<Screener>[] =>
  columns.map((column) => {
    const { fieldName, displayName } = column;
    const defaults = {
      header: displayName,
      accessorKey: fieldName,
    };

    switch (fieldName) {
      case "jobTitle":
        return {
          ...defaults,
          filterFn: customFilterFn,
          // function format here to make eslint happy.
          cell: function JobTitleCell(info: CellContext<Screener, string>) {
            const track = useTrackAnalytics();
            const { id } = info.row.original;

            return (
              <Link
                href={`/screener/${id}`}
                className="block font-bold text-[#784DD6] w-full"
                onClick={() => {
                  track("Screener Clicked", { screener_id: id });
                }}
              >
                {info.getValue()}
              </Link>
            );
          },
        };
      case "internalName":
        return {
          ...defaults,
          filterFn: customFilterFn,
          cell: textCell,
        };
      case "screenings":
        return {
          ...defaults,
          filterFn: customFilterFn,
          cell: textCell,
        };
      case "status":
        return {
          ...defaults,
          filterFn: customFilterFn,
          cell: badgeFilledCell(column),
        };
      case "location":
        return {
          ...defaults,
          filterFn: customFilterFn,
          cell: textCell,
        };
      case "lastSubmissionDate":
        return {
          ...defaults,
          ...createdAtCell(),
        };
      case "createdAt":
        return {
          ...defaults,
          ...createdAtCell(),
        };
      case "wage":
        return {
          ...defaults,
          filterFn: customFilterFn,
          cell: textCell,
        };
      default:
        return defaultCell(column);
    }
  });

export const formatScreenerLocationColumns = (
  columns: ApiColumn[],
): ColumnDef<ScreenerLocation>[] =>
  columns.map((column) => {
    const { displayName, fieldName } = column;
    const defaults = {
      header: displayName,
      accessorKey: fieldName,
    };

    switch (fieldName) {
      case "locationName":
        return {
          ...defaults,
          filterFn: customFilterFn,
          cell: (info) => <LocationNameCell info={info} />,
        };
      case "lastSubmissionDate":
        return {
          ...defaults,
          ...createdAtCell(),
        };
      case "shareLink":
        return {
          ...defaults,
          cell: (info) => <ShareLinkCell info={info} />,
        };
      case "status":
        return {
          ...defaults,
          filterFn: customFilterFn,
          cell: badgeFilledCell(column),
        };
      default:
        return defaultCell(column);
    }
  });

export const formatCandidateColumns = (
  columns: ApiColumn[],
  user: User | null,
): ColumnDef<Screening>[] => {
  const formattedColumns = columns.map((column): ColumnDef<Screening> => {
    const { fieldName } = column;
    const defaults = {
      header: column.displayName,
      accessorKey: fieldName,
    };

    if (fieldName.startsWith("competencies")) {
      const competencyName = fieldName.slice(fieldName.indexOf(".") + 1);

      return {
        accessorFn(originalRow) {
          return get(originalRow, ["competencies", competencyName]);
        },
        filterFn: customFilterFn,
        header: column.displayName,
        id: fieldName.replace(/\s+/g, ""),
        sortingFn: highMediumLowSortingFn,
        cell: (info: CellContext<Screening, Competency>) => (
          <CompetencyCell info={info} />
        ),
      };
    }

    if (fieldName.startsWith("isVisibleOnPdf")) {
      const propertyName = fieldName.slice(fieldName.indexOf(".") + 1);

      return {
        ...defaults,
        id: fieldName.replace(/\s+/g, ""),
        accessorFn(originalRow) {
          return get(originalRow, ["isVisibleOnPdf", propertyName]);
        },
        filterFn: customFilterFn,
        cell:
          column.type === "multiple_choice"
            ? (info: CellContext<Screening, string>) => {
                return (
                  <MultiBadgesCell
                    fieldName={fieldName}
                    sessionId={info.row.original.sessionId}
                    values={info.getValue()?.split(",") || []}
                  />
                );
              }
            : textCell,
      };
    }

    switch (fieldName) {
      case "candidateName":
        return {
          ...defaults,
          filterFn: (row, columnId, { defaultFilter: filterValue }) =>
            // Currently the only filter applied to this column is the "New
            // screenings" filter. If filterValue exists, we are only interested
            // in unviewed screenings
            filterValue && !row.original.viewedAt,
          cell: (info: CellContext<Screening, string>) => {
            return (
              <UnviewedIndicatorText
                indicatorText="NEW"
                primaryText={info.getValue()}
                showIndicatorText={!info.row.original.viewedAt}
              />
            );
          },
        };
      case "createdAt":
        return {
          ...defaults,
          ...createdAtCell(),
        };
      case "qualifyingPassRate":
        return {
          ...defaults,
          filterFn: companyFitFilterFn,
          cell: badgeFilledCell(column, "%"),
        };
      case "overallFit":
        return {
          ...defaults,
          filterFn: companyFitFilterFn,
          cell: badgeFilledCell(column),
          sortingFn: highMediumLowSortingFn,
        };
      case "words":
      case "totalWords":
        return timeAndWordsCell(column, defaults);
      case "time":
      case "totalTime":
        return timeAndWordsCell(column, defaults, false);
      default:
        return { ...defaults, filterFn: customFilterFn, cell: textCell };
    }
  });

  const actionsColumn = {
    header: "Actions",
    accessorKey: "actions",
    cell: (info: CellContext<Screening, void>) => (
      <div
        className="flex items-center gap-2"
        onClick={(e) => e.stopPropagation()}
      >
        <ActionMenu row={info.row.original} />
        {user?.internal && <ReprocessCell info={info} />}
      </div>
    ),
    enableSorting: false,
  };

  const highlightColorColumn = {
    header: "",
    accessorKey: "highlightColor",
    filterFn: highlightFilterFn,
    cell: () => {
      return null;
    },
    isVisible: false,
    enableSorting: false,
  };

  const selectionColumn: ColumnDef<Screening> = {
    id: "selection",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onChange={table.getToggleAllPageRowsSelectedHandler()}
        indeterminate={table.getIsSomePageRowsSelected()}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onChange={row.getToggleSelectedHandler()}
        onClick={(event) => {
          event.stopPropagation();
        }}
      />
    ),
  };

  return [
    selectionColumn,
    ...formattedColumns,
    actionsColumn,
    highlightColorColumn,
  ];
};
