import { type ApiColumn } from "@/types/api";
import { Nullable } from "@/types/utils";

export interface Competency {
  score: string;
  quote: string;
}

export interface Competencies {
  [name: string]: Competency;
}

export enum SessionStatus {
  Empty = "empty",
  Partial = "partial",
  Completed = "completed",
}

export enum ScreenerLocationStatus {
  Live = "Live",
  Paused = "Paused",
  Mixed = "Mixed",
}

export enum QuestionTypes {
  Behavioral = "behavioral",
  Knockout = "knockout",
}

export interface ScreenerLocation {
  createdAt: string;
  id: string;
  internalName?: string;
  jobName: string;
  locationName: string;
  positionLink: string;
  sessionCount?: number;
  shareLink: string;
  status: ScreenerLocationStatus;
  unviewedSessionCount: number;
  wage?: string;
}

interface Question {
  created_at: string;
  message: string;
  question_id: string;
  sender: "Candidate" | "Screener";
  type: QuestionTypes;
}

export interface BehavioralQuestion extends Question {
  type: QuestionTypes.Behavioral;
}

export interface KnockoutQuestion extends Question {
  answer_standardized?: string;
  is_visible_on_pdf: boolean;
  question_label: string;
  question_order: number;
  type: QuestionTypes.Knockout;
}

export enum HighlightColor {
  Red = "red",
  Blue = "blue",
  Orange = "orange",
}

// The by email response is formatted slightly differently from the by location response. We split them here for API
// responses and massage everything into `Screening` before passing it into the rest of the app.
interface BaseScreening {
  behavioralQuestions?: BehavioralQuestion[];
  candidateEmail: string;
  candidateLink?: string;
  candidateName: string;
  candidatePhone: string;
  createdAt: string;
  highlightColor?: HighlightColor;
  jobName: string;
  knockoutQuestions?: KnockoutQuestion[];
  language?: string;
  locationName: string;
  overallFit: string;
  pdfLink?: string;
  pdfLocation?: string;
  positionLink?: string;
  qualifyingPassRate: number;
  screenerDisplayName?: string;
  screenerLocation?: ScreenerLocation;
  sessionId: string;
  sessionStatus: SessionStatus;
  time: string;
  totalTimeInSeconds: number;
  totalWords: number;
  viewedAt?: string;
  words: string;
}

export interface Screening extends BaseScreening {
  competencies?: {
    [competency: string]: Competency;
  };
  // These are added onto screenings fetched for the table, but will not be
  // available on screenings fetched by user (as there would be no others to
  // compare them with)
  highTimeSpent?: boolean;
  highWordCount?: boolean;
  isVisibleOnPdf?: {
    [key: string]: string;
  };
}

export interface UserResponseScreening extends BaseScreening {
  competencyFields?: {
    [competency: string]: Competency;
  };
  isVisibleOnPdfFields?: {
    message: string;
    questionLabel: string;
  };
}

export interface CandidateResponseScreener {
  id: string;
  name: string;
  version: string;
  displayName: string;
  companyId: string;
  company: string;
}

export interface Screener {
  createdAt: string;
  id: string;
  internalName?: string;
  jobTitle: string;
  lastSubmissionDate?: string;
  location: string;
  screenings: number;
  status: ScreenerLocationStatus;
  wage: string;
}

// This interface represents the screener as returned from the
// `/screener/screener/cx/<SCREENER_ID>` endpoint. This endpoint returns a full
// screener rather than data massaged for the table format.
export interface FullScreener {
  closingStatement: string;
  createdAt: string;
  deletedAt: Nullable<string>;
  displayName: string;
  id: string;
  name: string;
  openingStatement: string;
  status: string;
  updatedAt: Nullable<string>;
  version: string;
}

// The by email response is formatted slightly differently from the by location response. We split them here for API
// responses and massage everything into `Screening` before passing it into the rest of the app.
interface BaseCandidateResponse {
  sessionId: string;
  columns: ApiColumn[];
  screener: CandidateResponseScreener;
}

export interface CandidateResponse extends BaseCandidateResponse {
  screenerSessions: Screening[];
}

export interface UserCandidateResponse extends BaseCandidateResponse {
  screenerSessions: UserResponseScreening[];
}

export interface AllScreenersResponse {
  columns: ApiColumn[];
  screenerRows: Screener[];
}

export interface AllLocationsResponse {
  columns: ApiColumn[];
  screenerLocations: ScreenerLocation[];
}

export interface CsvUploadTranslationResponse {
  success: boolean;
  message: string;
}
