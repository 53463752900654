import { faLock } from "@fortawesome/pro-solid-svg-icons/faLock";
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Button,
  Flex,
  Image,
  Modal,
  Text,
  TextInput,
} from "@mantine/core";

import { useUserContext } from "@/context/user.context";

interface AccountModalProps {
  onClose: () => void;
  opened: boolean;
}

export const AccountModal = ({ onClose, opened }: AccountModalProps) => {
  const { user } = useUserContext();

  return (
    <Modal
      centered
      onClose={onClose}
      opened={opened}
      styles={{
        body: {
          padding: "18px 24px 24px 24px",
        },
        content: {
          flex: "none",
        },
      }}
      withCloseButton={false}
    >
      <Flex align="flex-start" direction="column">
        <Flex align="center" className="w-full" justify="space-between">
          <Text fw={600}>Your account</Text>
          <ActionIcon onClick={onClose} variant="transparent">
            <Image
              src="/assets/circle-x.svg"
              alt="Close Account Modal"
              h={16}
            />
          </ActionIcon>
        </Flex>
        <TextInput
          className="w-[300px]"
          disabled
          label="Email"
          type="email"
          value={user?.email}
        />
        <TextInput className="w-[300px]" label="Full name" my={12} />
        <Button
          className="uppercase"
          color="#784DD6"
          rightSection={<FontAwesomeIcon icon={faPhone} />}
          p={0}
          variant="transparent"
        >
          Change Phone Number
        </Button>
        <Button
          className="uppercase"
          color="#784DD6"
          rightSection={<FontAwesomeIcon icon={faLock} />}
          p={0}
          variant="transparent"
        >
          Change Password
        </Button>
        <Flex mt={21}>
          <Button
            className="disabled:bg-[#15BFA3] disabled:opacity-50 disabled:text-white"
            color="#15BFA3"
            disabled
            mr={9}
          >
            Save changes
          </Button>
          <Button c="#333333" color="#F2F2F2">
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
